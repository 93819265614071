import React, { useLayoutEffect } from "react"
import ReactDOM from "react-dom"
import styled, { keyframes } from "styled-components"
import AlbumThumb, { StyledAlbumThumb } from "components/albumThumb"
import useAlbumData from "hooks/useAlbumData"

const outRight = keyframes`
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(100%,0,0);
  }
`
const inRight = keyframes`
  from {
    transform: translate3d(-100%,0,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
`

const inLeft = keyframes`
  from {
    transform: translate3d(100%,0,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
`
const outLeft = keyframes`
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(-100%,0,0);
  }
`

const outDown = keyframes`
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(0,100%,0);
  }
`
const inDown = keyframes`
  from {
    transform: translate3d(0,-100%,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
`

const outTop = keyframes`
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(0,-100%,0);
  }
`
const inTop = keyframes`
  from {
    transform: translate3d(0,100%,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
`

const StyledAlbumGridSlot = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: 0 0.25em 1.375em -0.75em rgba(0,0,0,0.6), 0 0.125em 0.25em -0.125em rgba(0,0,0,0.2);
  &::before {
    content: '';
    position: relative;
    display: block;
    padding-bottom: 100%;
    border-radius: inherit;
    background-color: var(--color-gray-text);
    opacity: 0.1;
  }
  ${StyledAlbumThumb} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`
const StyledAlbumGridSlotItem = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: ${outRight} 0.3s ease-in-out forwards;
  &.current {
    animation: ${inRight} 0.3s ease-in-out forwards;
  }
  ${StyledAlbumGridSlot}:nth-of-type(3n) & {
    animation: ${outDown} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inDown} 0.3s ease-in-out forwards;
    }
  }
  ${StyledAlbumGridSlot}:nth-of-type(3n + 1) & {
    animation: ${outLeft} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inLeft} 0.3s ease-in-out forwards;
    }
  }
  ${StyledAlbumGridSlot}:nth-of-type(3n + 2) & {
    animation: ${outTop} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inTop} 0.3s ease-in-out forwards;
    }
  }
  /*
  [data-direction="1"] & {
    animation: ${outRight} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inRight} 0.3s ease-in-out forwards;
    }
  }
  [data-direction="2"] & {
    animation: ${outDown} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inDown} 0.3s ease-in-out forwards;
    }
  }
  [data-direction="3"] & {
    animation: ${outLeft} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inLeft} 0.3s ease-in-out forwards;
    }
  }
  [data-direction="4"] & {
    animation: ${outTop} 0.3s ease-in-out forwards;
    &.current {
      animation: ${inTop} 0.3s ease-in-out forwards;
    }
  }
  */
`

const StyledAlbumGrid = styled.div`
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-gap: 0.5em;
  margin-bottom: 1.5em;
  ${StyledAlbumGridSlot}:nth-child(1) {
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;
  }
  ${StyledAlbumGridSlot}:nth-child(2) {
    grid-column: 1 / span 2;
    grid-row: 4 / span 2;
  }
  ${StyledAlbumGridSlot}:nth-child(10) {
    grid-column: 2 / span 2;
    grid-row: 7 / span 2;
  }
  @media ( min-width: 54em ) {
    grid-template: auto / repeat(6, 1fr);
    grid-gap: 0.5em;
    margin-bottom: 3em;
    ${StyledAlbumGridSlot}:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 2 / span 2;
    }
    ${StyledAlbumGridSlot}:nth-child(2) {
      grid-column: 5 / span 2;
      grid-row: 1 / span 2;
    }
    ${StyledAlbumGridSlot}:nth-child(10) {
      grid-column: 4 / span 2;
      grid-row: 3 / span 2;
    }
  }
`



const AlbumGrid = ({ data }) => {

  const numbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  const directions = [1,2,3,4]

  const { albums } = useAlbumData()

  const shuffledArtists = albums.edges.sort(function() {
      return .5 - Math.random()
  });

  function showHideGridSlots() {
    const hideables = document.querySelectorAll('.hideable')
    console.log(hideables)
    if ( matchMedia(`(max-width: 48em)`).matches ) {
      hideables.forEach(function (el, index) {
        el.setAttribute('hidden', true)
      })
    }
    else {
      hideables.forEach(function (el, index) {
        el.removeAttribute('hidden')
      })
    }
  }

  useLayoutEffect(() => {

    let currentArtist = 0

    function rotate() {
      let number = 0
      let direction = 0
      let artist = shuffledArtists[currentArtist];
  
      currentArtist = currentArtist + 1 === shuffledArtists.length ? 0 : currentArtist + 1;
  
      while ( number === 0 ) {
          const candidate = numbers[numbers.length * Math.random() | 0];
          if ( ! document.getElementById(`artist-item--${candidate}`).classList.contains('pause') ) {
              number = candidate;
              break;
          }
      }
  
      const gridSlot = document.getElementById(`artist-item--${number}`)
      const gridSlotItemCurrent = gridSlot.querySelector('.slot-item.current')
      const gridSlotItemNext = gridSlot.querySelector('.slot-item:not(.current)')
  
  
      while ( direction === 0 ) {
        const candidate = directions[directions.length * Math.random() | 0]
        if ( gridSlot.getAttribute('data-direction') !== candidate ) {
            direction = candidate;
            break;
        }
      }
      
      const isLarge = number < 3 ? true : false
  
      gridSlot.setAttribute('data-direction', direction)
      gridSlot.classList.add('pause');
      setTimeout(() => {
        gridSlot.classList.remove('pause')
      }, 6000 );
      gridSlot.classList.add('changing');
      gridSlot.setAttribute('data-current-item', artist.node.id);
      gridSlot.setAttribute('data-transition-count', Number(gridSlot.getAttribute('data-transition-count')) + 1);
      
  
      setTimeout(() => {
          ReactDOM.render(<AlbumThumb albumData={artist.node} isLarge={isLarge} />, gridSlotItemNext);
          gridSlotItemNext.classList.add('current')
          gridSlotItemCurrent.classList.remove('current')
          gridSlot.classList.remove('changing');
      }, 1000)
    }
    
    showHideGridSlots()

    window.addEventListener('resize', showHideGridSlots)

    for ( let index of numbers ) {
        const gridSlot = document.getElementById(`artist-item--${index}`)
        const gridSlotItem = gridSlot.querySelector('.slot-item:not(.current)')
        const isLarge = index < 4 ? true : false
        const direction = directions[directions.length * Math.random() | 0]
        gridSlot.setAttribute('data-direction', direction)
        gridSlot.setAttribute('data-current-item', shuffledArtists[currentArtist].node.id)
        gridSlot.setAttribute('data-transition-count', 1)
        ReactDOM.render(<AlbumThumb albumData={shuffledArtists[currentArtist].node} isLarge={isLarge} />, gridSlotItem)
        gridSlotItem.classList.add('current')
        currentArtist += 1
    }

    
    const albumInterval = setInterval( rotate, 1500)
  
    return function cleanup() {
      clearInterval(albumInterval)
    }
  })

  

  return(
    <StyledAlbumGrid>
      {numbers.map((number) =>
        <StyledAlbumGridSlot id={`artist-item--${number}`} key={number} className={number > 9 ? `hideable` : null}>
          <StyledAlbumGridSlotItem className={`slot-item`}></StyledAlbumGridSlotItem>
          <StyledAlbumGridSlotItem className={`slot-item`}></StyledAlbumGridSlotItem>
        </StyledAlbumGridSlot>
      )}
    </StyledAlbumGrid>
  )
}

export default AlbumGrid