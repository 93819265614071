import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import NextRelease from "components/nextRelease"
import BookLarge from "components/BookLarge"
import AlbumGrid from "components/albumGrid"
import Map, { StyledMapContentWrap } from "components/map/map"
import Hero from "components/hero"
import Filters from "components/filters/filters"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"

const StyledArtistsWrap = styled.div`
  position: relative;
  z-index: 1;
  padding: 2em 0;
  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: var(--backdrop-filter);
    background-color: var(--color-white);
  }
  &::after {
    background-color: var(--color-background);
    opacity: 0.3;
  }
  @media ( min-width: 54em ) {
    padding: 3em 0 2em;
  }
  @media ( min-width: 60em ) {
    .show-player &::before,
    .show-player &::after {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
`

const StyledWideMap = styled.div`
  position: relative;
  z-index: 3;
  color: var(--color-white);
  & > div:first-of-type {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
  }
  & > div:first-of-type::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: var(--backdrop-filter);
  }
  h3 {
    position: absolute;
    right: 0;
    left: 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.125em;
    font-weight: 400;
    margin: -2em 0 0;
    strong {
      font-weight: 700;
    }
  }
  @media ( max-width: 54em ) {
    margin-left: -8vw;
    width: 100vw;
    ${StyledMapContentWrap} {
      padding: 2.5em 0.5em;
    }

    h3 {
      margin-top: -4.5em;
      strong {
        display: block;
      }
    }
  }
  @media ( min-width: 54em ) {
    padding: 3em 0;
    h3 {
      font-size: 1.375em;
    }
  }
  @media ( min-width: 60em ) {
    .show-player & > div:first-of-type {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
`

const IndexPage = ({ data }) => (
  <>
    <Helmet>
      <title>The Outlaw Ocean Music Project</title>
    </Helmet>
    <main>
      <Hero 
        image={data.backgroundImage.childImageSharp.fluid}
      />
      <AwardAnnouncement />
      <Filters hideMap />
      <StyledArtistsWrap>
        <AlbumGrid />
        <NextRelease />
      </StyledArtistsWrap>
      <StyledWideMap>
        <div>
          <Img fluid={data.backgroundImage.childImageSharp.fluid} alt="" />
        </div>
        <Map />
        <h3>Hundreds of Musicians, <strong>One Mission</strong></h3>
      </StyledWideMap>
      <BookLarge />
    </main>
    <StyledNotice>
      <a href="https://theoutlawocean.com/merchandise">
        Show your support: Bracenet x The Outlaw Ocean Project
      </a>
    </StyledNotice>
  </>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      id
      siteMetadata {
        title
      }
    }
    backgroundImage: file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1550) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  }
`

const StyledNotice = styled.aside`
  position: relative;
  z-index: 5;
  a {
    position: relative;
    display: block;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    padding: 0.5em 8%;
    text-align: center;
    background-color: var(--color-gray);
    font-weight: 500;
    font-size: 0.875em;
    .can-hover &:hover,
    &:active {
      text-decoration: underline;
    }
  }
`