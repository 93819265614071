import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { LightButton } from "components/button"



const StyledBook = styled.aside`
    position: relative;
    z-index: 5;
    padding: 3em 0;
    text-align: center;
    color: var(--color-white);
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100vw;
        margin-left: -50vw;
        background-color: var(--color-dark);
        background-color: rgba(1, 46, 51, 0.95);
        backdrop-filter: var(--backdrop-filter);
        background-color: var(--color-white);
    }
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100vw;
        margin-left: -50vw;
        background-color: var(--color-dark);
        background-color: rgba(1, 46, 51, 0.95);
        backdrop-filter: var(--backdrop-filter);
        background-color: var(--color-gray-dark);
        opacity: 0.7;
        background-color: var(--color-dark);
        opacity: 1;
    }
    /*
    h2 {
        font-size: var(--font-big);
        line-height: var(--font-big);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
        em {
            font-weight: 700;
            font-style: normal;
        }
    }
    */
    
    @media ( max-width: 48em ) {
        > figure {
            position: relative;
            width: 100vw;
            left: 50%;
            margin-left: -50vw;
            overflow: hidden;
            & > .gatsby-image-wrapper {
              right: -10%;
              left: -10%;
              width: 120%;
            }
        }
    }
    @media ( min-width: 48em ) {
        padding: 6em 0;
    }
    @media ( min-width: 60em ) {
      .show-player &::before,
      .show-player &::after {
        width: calc(100vw - (200px + 3em));
        left: -3em;
        margin-left: 0;
      }
    }
`

const StyledBookCredits = styled.div`
    position: relative;
    margin-bottom: 2em;
    p {
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 0.1em;
    }
    figure {
        width: 12em;
        margin: 0 auto;
    }
    @media ( min-width: 48em ) {
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            margin: 0;
        }
        p {
            position: relative;
            margin-right: 1.25em;
            padding-right: 1.25em;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -1.5em;
                height: 3em;
                right: 0;
                width: 0.075em;
                background-color: currentColor;
            }
        }
    }
`

const StyledBookCopy = styled.div`
    text-align: left;
    max-width: 66em;
    margin: 1.5em auto;
    h3 {
        font-size: 1.25em;
        font-weight: 500;
        margin: 0 auto;
    }
    h3 + p {
        margin-top: 0;
    }
    @media ( min-width: 48em ) {
        display: grid;
        grid-template: auto / repeat(2, 1fr);
        grid-gap: 0 4em;
        margin: 3em auto;
    }
`



export default (props) => {
    const data = useStaticQuery(
        graphql`
            query {
                content: directusBook {
                    homepage_column_one
                    homepage_column_two
                }
                books: file(relativePath: { eq: "UPDATE5covers.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        fluid(maxWidth: 1800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                nyt: file(relativePath: { eq: "nyt-best-seller.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )
    return (
        <StyledBook>
            <h2 className="pageTitle">Music Inspired by <strong>The Outlaw Ocean</strong></h2>
            <StyledBookCredits>
                <p>A Book by Ian Urbina</p>
                <figure>
                    <Img fluid={data.nyt.childImageSharp.fluid} alt="" />
                </figure>
            </StyledBookCredits>
            <figure>
                <Img fluid={data.books.childImageSharp.fluid} alt="" />
            </figure>
            <StyledBookCopy>
                <div dangerouslySetInnerHTML={{ __html: data.content.homepage_column_one }} />
                <div dangerouslySetInnerHTML={{ __html: data.content.homepage_column_two }} />
            </StyledBookCopy>
            <LightButton as="a" href="https://www.theoutlawocean.com/book" target="_blank" rel="noopener noreferrer">Learn More about the Book</LightButton>
        </StyledBook>
    )
}
