import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"


const StyledAlbumThumb = styled.div`
	position: relative;
`

const StyledAlbumThumbCoverLink = styled.a`
	position: relative;
  display: block;
  border-radius: var(--border-radius);
	.can-hover &:not(:last-child)::after {
		content: "View Artist";
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
    font-size: 0.75em;
    font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		line-height: 1.2;
		color: var(--color-white);
		opacity: 0;
		transform: translate3d(-50%, -50%,0);
		transition: opacity 0.3s ease;
	}
	.can-hover &:not(:last-child):hover::after {
		opacity: 1;
		transition: opacity 0.3s ease 0.1s;
	}
`


const StyledAlbumThumbCover = styled.figure`
    position: relative;
    z-index: 1;
    margin: 0;
    padding-bottom: 100%;
    overflow: hidden;
	  transform: scale(1) translate3d(0,0,0);
	  transition: transform 0.3s ease;
	  background-color: var(--color-offwhite);
    box-shadow: 0 0.25em 1.375em -0.75em rgba(0,0,0,0.6), 0 0.125em 0.25em -0.125em rgba(0,0,0,0.2);
    border-radius: var(--border-radius);
    .gatsby-image-wrapper {
        position: absolute !important;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        img {
          border-radius: var(--border-radius);
        }
    }
	html:not(.can-hover) ${StyledAlbumThumbCoverLink}:active & {
		transform: scale(0.95);
	}
    .can-hover &::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-black);
        transform: translate3d(-101%,0,0);
        transition: transform 0.4s var(--bezier);
        border-radius: var(--border-radius);
    }
    .can-hover ${StyledAlbumThumbCoverLink}:hover &::after {
        transform: translate3d(0,0,0);
    }
	
`

const StyledAlbumThumbMeta = styled.div`
	position: relative;
    margin-top: 0.75em;
	padding-right: 2.5em;
	${StyledAlbumThumbCoverLink} & {
		position: absolute;
		z-index: 3;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 1em;
		overflow: hidden;
		color: var(--color-white);
		opacity: 0;
		transition: opacity 0.3s ease 0.1s;
		.can-hover &:hover,
		&:active {
			opacity: 1;
			transition: opacity 0.3s ease;
		}
	}
	
	h3 {
    font-size: 0.875em;
    font-weight: 700;
    text-transform: none;
		margin: 0;
		line-height: 1.2;
	}
	h4 {
		font-size: 0.875em;
		font-weight: 500;
    margin: 0.25em 0 0;
    text-transform: none;
		line-height: 1.2;
	}
	p {
		font-size: var(--font-tiny);
		line-height: var(--font-tiny-lh);
		margin: 0;
	}
`

const AlbumThumb = (props) => {
	
  const album = props.albumData
  let albumImage = false
  if ( props.isLarge && album.cover.localFile ) {
    albumImage = album.cover.localFile.childImageSharp.large
  }
  else if ( album.cover && album.cover.localFile ) {
    albumImage = album.cover.localFile.childImageSharp.fluid
  }
  return (
    <StyledAlbumThumb>
      <StyledAlbumThumbCoverLink as={Link} to={`/artists/${album.artist.slug}`}>
        <StyledAlbumThumbCover>
          <Img fluid={albumImage} alt={`${album.title} by ${album.artist.name}`} />
        </StyledAlbumThumbCover>
        <StyledAlbumThumbMeta>
          <h3>{album.artist.name}</h3>
          <h4>{album.title}</h4>
          <p>{album.artist.location && album.artist.location}</p>
        </StyledAlbumThumbMeta>
      </StyledAlbumThumbCoverLink>
    </StyledAlbumThumb>
  )
}

export { StyledAlbumThumb }
export default AlbumThumb